.schedule-container {
  padding: 20px;
  font-size: 16px;
  color: #333;
}

.schedule-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}