/* App.css */

.App {
  display: flex;
  font-family: Arial, sans-serif;
  height: 100vh;
}

.sidebar {
  width: 200px;
  background-color: #f8f9fa;
  padding: 15px;
  height: 100%;
}

.sidebar a {
  display: block;
  color: #333;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 18px;
}

.sidebar a:hover {
  color: #007bff;
}

.content {
  flex-grow: 1;
  padding: 15px;
}