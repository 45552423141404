.amortizationSchedule {
  display: flex;
  flex-direction: column;
}

.amortizationMonth {
  display: flex;
  border: 1px solid black;
  margin: 2px;
  padding: 2px; /* reducir el relleno para hacer las filas más pequeñas */
}

.amortizationMonth>p {
  flex: 1;
  text-align: center;
}

/* AmortizationCalculator.css */

.calculator {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.calculator input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
}

.calculator button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.calculator button:hover {
  background-color: #0056b3;
}