.container {
    padding: 10px;
    font-size: 16px;
    color: #333;
}

.header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 10px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    text-align: left;
}