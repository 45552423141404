.table {
  border-collapse: collapse;
  width: 100%;
}

.th, .td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.thead {
  background-color: #f2f2f2;
}